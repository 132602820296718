import Glide from '@glidejs/glide';
import imagesLoaded from 'imagesloaded';

var Isotope = require('isotope-layout');
var Rellax = require('rellax/rellax');
import smoothscroll from 'smoothscroll-polyfill';
import 'lazysizes';

/**
 * NodeList.prototype.forEach() polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
 */
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

/* Rellax */
var Rellax = new Rellax('.rellax', {
  speed: 2,
  center: false,
  wrapper: null,
  round: true,
  vertical: true,
  horizontal: false
});

// kick off the polyfill!
smoothscroll.polyfill();

/* Smooth Scroll */
window.addEventListener('load', () => {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {

  // Handler that uses various data-* attributes to trigger
  // specific actions, mimicing bootstraps attributes
  const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));

  window.addEventListener('click', (ev) => {
    const elm = ev.target;
    if (triggers.includes(elm)) {
      const selector = elm.getAttribute('data-target');
      collapse(selector, 'toggle');
    }
  }, false);


  const fnmap = {
    'toggle': 'toggle',
    'show': 'add',
    'hide': 'remove'
  };

  const collapse = (selector, cmd) => {
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach(target => {
      target.classList[fnmap[cmd]]('show');
    });
  }


  /* Product Gallery Slider */
  var bodyClass = document.querySelector('body.glider');

  if (bodyClass != null) {
    new Glide('.glide',
      {startAt: 0, autoplay: 4100}).mount()
  }

  /* Simply Tasty Category Sliders */

  var simplyHome = document.querySelector('body.simply-home');

  if (simplyHome != null) {
    var gliders = document.getElementsByClassName('glide');
    for (var i = 0, len = gliders.length; i < len; i++) {
      var glider = gliders[i];
      new Glide(glider,
        {
          startAt: 0,
          perView: 4,
          bound: true,
          breakpoints: {
            800: {
              perView: 2
            }
          }
        }).mount()
    }
  }


  /* Products Isotope Grid */
  const getHashFilter = () => {
    // get filter=filterName
    var matches = location.hash.match(/filter=([^&]+)/i);
    var hashFilter = matches && matches[1];
    return hashFilter && decodeURIComponent(hashFilter);
  }

  var grid = document.querySelector('.grid');

  if (grid != null) {
    var filterButtonGroup = document.querySelector('.filter-button-group');
    var iso;

    const onHashchange = () => {
      var hashFilter = getHashFilter();
      if (!hashFilter && iso) {
        return;
      }
      if (!iso) {
        // init Isotope for first time
        imagesLoaded(grid, () => {
          // init Isotope after all images have loaded
          iso = new Isotope(grid, {
            itemSelector: '.element-item',
            layoutMode: 'fitRows',
            filter: hashFilter || '',
          });
        });
      } else {
        // just filter with hash
        iso.arrange({
          filter: hashFilter
        });
      }

      // set selected class on button
      if (hashFilter) {
        var checkedButton = filterButtonGroup.querySelector('.is-checked');
        if (checkedButton) {
          checkedButton.classList.remove('is-checked');
        }
        filterButtonGroup.querySelector('[data-filter="' + hashFilter + '"]').classList.add('is-checked');
      }
    }

    window.addEventListener('hashchange', onHashchange);
    // trigger event handler to init Isotope
    onHashchange();


    // bind filter button click
    filterButtonGroup.addEventListener('click', (event) => {
      var filterAttr = event.target.getAttribute('data-filter');
      if (!filterAttr) {
        return;
      }
      location.hash = 'filter=' + encodeURIComponent(filterAttr);
    });
  }

  /* Home Hero section auto height */

  var viewportWidth;
  var viewportHeight;
  var barHeight;
  var headerHeight;

  var hamburger = {
    navToggle: document.querySelector('.nav-toggle'),
    nav: document.querySelector('nav'),

    doToggle: (e) => {
      e.preventDefault();
      document.querySelector('.nav-toggle').classList.toggle('expanded');
      document.querySelector('nav').classList.toggle('expanded');
    }
  };

  var hero = document.querySelector('.hero');
  var bar = document.querySelector('.horiz-bar');
  var header = document.querySelector('header');

// Set/update the viewportWidth value
  var setViewportWidth = () => {
    viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (viewportWidth < 992) {
      hamburger.navToggle.addEventListener('click', (e) => {
        hamburger.doToggle(e);
      });
    }
  }


// Set/update the viewportHeight value
  var setViewportHeight = () => {
    viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    barHeight = bar.offsetHeight || bar.clientHeight;
    headerHeight = header.offsetHeight || header.clientHeight;

    var heroHeight = viewportHeight - (barHeight + headerHeight);
    if(!!hero){
      hero.style.height = heroHeight + 'px';
    }

  }


  setViewportWidth();
  if(!!bar){
    setViewportHeight();
  }

  // On resize events, recalculate and log
  window.addEventListener('resize', () => {
    setViewportWidth();
    setViewportHeight();
  }, false);
});


